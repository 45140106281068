import Header from '../../components/header/Header';
import TopPage from '../../components/topPage/TopPage';
import Emotions from '../../components/emotions/Emotions';
import Descriptions from '../../components/descriptions/Descriptions';
import Levels from '../../components/levels/Levels';
import WhoFor from '../../components/whoFor/WhoFor';
import CityChoice from '../../components/cityChoice/CityChoice';
import styles from './MainScreen.module.scss';
import Registration from '../../components/registration/Registration';
// import Partners from '../../components/partners/Partners';
import Gallery from '../../components/gallery/Gallery';
import MapComponent from '../../components/map/Map';
import Footer from '../../components/footer/Footer';
import Faq from '../../components/faq/Faq';
import TableFormats from '../../components/TableFormats/TableFormats';
// import Engraving from '../../components/engraving/Engraving';

function MainScreen({cityIdScreen}) {

  return (
    <>
      <Header />
      <TopPage />
      <Emotions />
      <Descriptions />
      <Levels />
      {/*<WhoFor />*/}
      <CityChoice cityIdScreen={cityIdScreen} />
      {/* <Engraving cityIdScreen={cityIdScreen} /> */}
      <TableFormats />
      <div className={styles.back}>
        <Registration />
        {/*<Partners />*/}
      </div>
      <Gallery />
      <Faq />
      <MapComponent />
      <Footer />
    </>
  );
}

export  default MainScreen;
